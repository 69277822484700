import styled, { css } from "styled-components";
import { StyledContentWrapper } from "../../styles/styledContainer";
import { StyledSiteOptionsWrapper } from "../../elements/siteOptions/styledSiteOptions";
import { device, hideOnMedia } from "../../styles/responsive/responsiveUtils";
import { StyledCol, StyledColHead } from "../../styles/styledTable";

export const StyledDiceWrapper = styled.section`
  ${({ isMobile }) => !isMobile ? '' : 'position: relative;'}
  ${({ isMobile }) => !isMobile ? 'padding: 30px 0 0;' : 'padding: 20px 0 20px;'}
  ${({ isMobile }) => !isMobile ? 'height: 470px;' : 'height: 283px;'}
  ${({ isMobile }) => !isMobile ? '' : 'margin: 0 auto;'}
  ${({ isMobile }) => !isMobile ? '' : 'box-sizing: border-box;'}
  ${({ isMobile }) => !isMobile ? '' : 'width: 100%'}
  ${({ isMobile }) => !isMobile ? '' : 'max-width: 1100px;'}
  background: url("${({
    theme,
    isMobile
  }) => isMobile ? theme.bgMobileGame : theme.bgGame}") ${({ isMobile }) => isMobile ? 'no-repeat 50% -145px' : ' no-repeat 50% 0'};

  &:hover {
    ${StyledSiteOptionsWrapper} {
      .jackpot {
        opacity: ${({ theme }) => theme.optionOpacityHoverContainer};

        &:hover {
          opacity: 1;
          background-color: ${({ theme }) => theme.optionBackgroundHover};
          img {
            opacity: 1 !important;
            transition: opacity 0.3s;
          }
        }
        img {
          transition: opacity 0.3s;
        }
      }

      .panel {
        &__item {
          opacity: ${({ theme }) => theme.optionOpacityHoverContainer};

          &:hover {
            opacity: 1;
            background-color: ${({ theme }) => theme.optionBackgroundHover};
            .icon {
              opacity: 1;
              transition: opacity 0.3s ease-out;
            }
          }
          .icon {
            transition: opacity 0.3s ease-out;
          }
        }
      }
    }
  }
`;

export const StyledDiceContentWrapper = styled.div`
  max-width: ${({ width }) => width ? width + "px" : "1000px"};
  display: ${({ isFlex }) => isFlex ? "flex" : "block"};
  flex-direction: ${({ column }) => column ? "column" : "row"};
  align-items: ${({ position }) => position ? position : "unset"};
  width: 100%;
  margin: 0 auto;
  @media screen and ${device('desktop')} {
    padding: 0 20px;
  }
`;


export const StyledDiceNumberWrapper = styled.div`
  padding-top: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  @media screen and ${device('desktop')} {
    max-width: 335px;
    margin: 0 auto;
  }

  ${({ isAbsolute }) => isAbsolute ? css`
    z-index: 0;
    margin-top: -230px;
    padding-top: 0;
    justify-content: space-between;
    width: 1000px;
    padding-left: 10px;
    padding-right: 10px;
    height: 230px;
    @media screen and ${device('desktop')} {
      pointer-events: auto;
      // flex-wrap: wrap;
      position: relative;
      // margin-top: -100px;
      height: auto;
      justify-content: center;
    }
  ` : css`
    @media screen and ${device('desktop', 'min')} {
      padding-top: 36px;
    }
  `};
`;

export const StyledDiceBox = styled.div`
  width: 467px;
  height: 230px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  position: relative;
  padding: 0 40px 0;

  ${({ theme }) => theme.diceBox};

  &:before {
    content: "";
    width: 5px;
    height: 100%;
    margin-left: -2.5px;
    display: block;
    pointer-events: none;
    background: ${({ theme }) => theme.diceBoxLine};
    position: absolute;
    top: 0;
    left: 50%;
  }

  &:after {
    content: "";
    position: absolute;
    width: 104%;
    bottom: -6px;
    left: -10px;
    height: 10px;
    z-index: 0;
    ${({ theme }) => theme.diceBoxShadow};
  }

  .dice-number {
    width: 150px;
    height: 220px;
    padding-right: 2px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-top: 2px;
      @media screen and ${device('desktop')} {
        margin-top: -5px;
      }
    }

    & div {
      margin-bottom: 0;
    }

    input {
      padding: 0;
      width: 100%;
      color: ${({ theme }) => theme.subTitleColor};
      font-size: 130px;
      font-weight: 500;
      text-align: center;
      line-height: 115px;
      background: none;
      border: none;
      box-shadow: none;
      margin-top: 0px;
      @media screen and ${device('desktop')} {
        height: 72px;
        line-height: inherit;
        font-size: 50px;
        margin-bottom: 1px;
      }
    }

    &__result input {
      color: ${({ isLose, theme }) => isLose ? theme.diceNumber.lose : theme.diceNumber.win};
    }

    @media screen and ${device('desktop')} {
      height: 140px;
      justify-content: flex-start;
      padding-top: 20px;
    }
  }

  @media screen and ${device('desktop')} {
    width: 335px;
    height: 146px;
    padding: 0 7px;
    font-size: 13px;
    margin-bottom: 20px;
    background-color: ${({ theme }) => theme.diceNumber.backgroundColor};

    &:after {
      background: transparent;
      box-shadow: none;
    }
  }
`;

export const StyledDiceDirection = styled.div`
  order: ${({ direction }) => !direction && "-1"};

  button {
    color: #777777;
    font-size: 32px;
    text-transform: uppercase;
    border: none;
    outline: none;
    position: relative;
    top: -3px;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      color: ${({ theme }) => theme.subTitleColor};
      opacity: 1;
    }

    @media screen and ${device('desktop')} {
      font-size: 20px;
      color: #777777;
      top: unset;
      margin: 0 10px;
      &:hover {

        opacity: 1;
        color: ${({ theme }) => '#777777'};
      }
    }
  }

  button.active {
    color: ${({ theme }) => theme.subTitleColor};

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: ${({ theme }) => theme.subTitleColor};
      position: absolute;
      top: 100%;
      left: 0;
      @media screen and ${device('desktop')} {
        display: none;
        &:hover {

          opacity: 1;
          color: ${({ theme }) => theme.subTitleColor};
        }
      }
    }
  }

  @media screen and ${device('desktop')} {
    order: 0;
  }
`;

export const StyledDiceBetWrapper = styled.div`
  background-color: ${({ theme }) => theme.elementBg};
`;

export const StyledDiceBetContent = styled(StyledContentWrapper)`
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);

  @media screen and ${device('desktop')} {
    display: flex;
    justify-content: center;
    padding: 0;
  }
`;

export const StyledDiceBetOptions = styled.div`
  padding: 20px 0 20px 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  justify-content: space-between;

  @media screen and ${device('desktop')} {
    padding: 20px 20px 30px 20px;
    max-width: 1000px;
    width: 100%;
    flex-direction: column-reverse;
  }

  @media screen and ${device('desktop', 'min')} {
    grid-gap: 15px;
  }

  & .wrapper-group {
    display: grid;
    grid-template-columns: 270px 120px;
    grid-gap: 10px 2%;

    @media screen and ${device('desktop')} {
      grid-template-columns: 64% 34%;
    }

    @media screen and ${device('desktop', 'min')} {
      grid-gap: 10px 20px;
    }

    button {
      margin-top: 0;
    }
  }

  .input-group {
    margin: 1px 0;

    label, input {
      text-align: center;
    }
  }
`;

export const StyledDiceHistoryWrapper = styled.div`
  padding: 25px 0 10px;
  background-image: url("${({ theme }) => theme.bgDiceHistory}");
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;

  .default-tabs-top {
    gap: 10px;
  }
  
  .dice-history-table {
    padding: 0 0 10px;

    @media screen and ${device('desktop')} {
      font-size: 12px;
    }

    &__head, &__row {
      grid-template-columns: minmax(55px, 1fr) minmax(110px, 1fr) 40px minmax(240px, 1fr) minmax(110px, 1.5fr) minmax(110px, 1.5fr);

      @media screen and ${device(700, 'min')} and ${device(900)} {
        grid-template-columns: minmax(55px, 1fr) minmax(110px, 1fr) 40px minmax(200px, 1fr) minmax(90px, 1.5fr) minmax(90px, 1.5fr);
      }

      @media screen and ${device('xs', 'min')} and ${device(700)} {
        grid-template-columns: minmax(55px, 1fr) minmax(90px, 1fr) 40px minmax(100px, 1fr) minmax(90px, 1.5fr) minmax(90px, 1.5fr);
      }

      @media screen and ${device('xs')} {
        grid-template-columns: 25% 25% 15% 0 0 25%;
      }
    }

    &_loading,
    &_empty {
      padding: 12px 15px;
      grid-template-columns: 1fr;
      text-align: center;
      text-transform: uppercase;
    }

    &__currency {
      text-align: center;
      justify-content: center;
    }

    &__bet {
      p {
        font-size: 12px;
        text-align: center;
      }
    }

  }

  .custom-hint {
    position: relative;
    width: 0;
    transform: translate(20px, -31px);
    z-index: 100;

    @media screen and (max-width: 375px) {
      transform: translate(0, -31px);
    }
  }

  @media screen and ${device('desktop')} {
    padding: 0;
  }
`;

export const StyledDiceHistoryProgress = styled.div`
  width: 100%;
  height: 15px;
  margin-top: 3px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.diceHistory.progressBg};
  display: flex;
  justify-content: ${({ sign }) => sign === 1 ? "flex-end" : "flex-start"};
  align-items: center;
  position: relative;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 1);

  .bet-progress {
    &__line {
      width: calc(99% - ${({ suggestedNumbers, sign }) => sign === 1 ? suggestedNumbers : 99 - suggestedNumbers}%);
      height: 14px;
      border-radius: 7px;
      background-image: linear-gradient(to top, ${({ losing }) => losing ? "#11492f, #009850" : "#4c1019, #ab2e40"});
      box-shadow: inset 0 1px rgb(255 255 255 / 50%), 0 0 1px 1px rgb(0 0 0 / 25%), 0 1px rgb(0 0 0 / 20%);
    }

    &__count {
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: ${({ drawnNumber }) => drawnNumber}%;
      background: ${({ theme }) => theme.diceHistory.numBg};
    }

    &__num {
      min-width: 20px;
      padding: 1px 4px 2px;
      color: ${({ theme }) => theme.diceHistory.numColor};
      font-size: 11px;
      line-height: normal;
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
      border-radius: 2px;
      border: 0 solid #000000;
      background-color: ${({ theme }) => theme.diceHistory.numBg};
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const StyledAboutDice = styled.div`
  max-width: 900px;
  margin: 0 auto 50px;
  @media screen and ${device('desktop')} {
    padding: 0 5%;
  }
`;

export const StyledDesktopColHead = styled(StyledColHead)`
  ${hideOnMedia('xs')}
`;

export const StyledDesktopCol = styled(StyledCol)`
  ${hideOnMedia('xs')}
`;

export const StyledDesktopSpan = styled.span`
  ${hideOnMedia(900)}
`;
