import styled, { css, keyframes } from "styled-components";

export const ConfirmationPayoutModalContainer = styled.div`
    position: relative;
`

export const PayoutValueContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
`

export const PayoutValueTitle = styled.span`
    margin-bottom: 10px;
`

export const PayoutValue = styled.span`
    color: ${({theme}) => theme.subTitleColor};
    font-size: 28px;
    font-weight: 500;
`

export const Line = styled.div`
    display: flex;
    height: 1px;
    width: 100%;
    background: ${({theme}) => theme.mainTextColor};
    border-radius: 100%;
`

export const PayoutPaymentInfoContainer = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    
    .input-group {
        margin-bottom: 10px;
        margin-top: 10px;
    }
`

export const PayoutPaymentInfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 15px;
`

export const PayoutPaymentInfoTitle = styled.span`
    color: ${({theme}) => theme.mainTextColor};
    text-transform: capitalize;
`

export const PayoutPaymentInfoValue = styled.span`
    color: ${({theme}) => theme.secondTextColor};
    max-width: 80%;
    word-wrap: break-word;
    overflow-wrap: break-word;
`

const rotate = keyframes`
    from {
        transform: rotate(-30deg);
    }
    to {
        transform: rotate(-390deg);
    }
`;

export const ReloadFeeButton = styled.button`
    width: 14px;
    height: 14px;
    background: none;
    outline: none;
    border: none;
    box-shadow: none;
    cursor: pointer;

    img {
        width: 100%;
        height: auto;
        margin-bottom: -2px;
        transform: ${({ isAnimate }) => (isAnimate ? 'rotate(-30deg)' : 'rotate(-390deg)')};
        ${({ isAnimate }) =>
                isAnimate &&
                css`
        animation: ${rotate} 1s linear infinite;
      `}
    }
`;

export const LeftArrowIcon = styled.button`
    width: 25px;
    background: none;
    outline: none;
    border: none;
    box-shadow: none;
    position: absolute;
    top: -20px;
    left: 0;
    
    img {
        width: 100%;
        height: auto;
    }
`