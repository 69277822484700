import { useState, useMemo } from "react";
import Cookies from "js-cookie";

const BalanceStates = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [balance, setBalance] = useState(0);
  const [profit, setProfit] = useState({
    win: null,
    lose: null
  });
  const [activeCurrency, setActiveCurrency] = useState(null);
  const [paymentDialogVisible, setPaymentDialogVisible] = useState(false);
  const [payoutDialogVisible, setPayoutDialogVisible] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [balanceSync, setBalanceSync] = useState(undefined);
  const [coinConfigDialogVisible, setCoinConfigDialogVisible] = useState(false);
  const [coinConfig, setCoinConfig] = useState({
    assetsVisibility: Cookies.get('assetsVisibility') ?? 'all',
    coinShownNames: Cookies.get('coinShownNames') ?? 'name'
  });

  const selectedPaymentMethod = useMemo(() => paymentMethods
    ? paymentMethods.find((item) => item?.currency.asset === activeCurrency)
    : null, [paymentMethods, activeCurrency]);

  return {
    paymentMethods,
    setPaymentMethods,
    selectedPaymentMethod,
    balance,
    setBalance,
    activeCurrency,
    setActiveCurrency,
    paymentDialogVisible,
    setPaymentDialogVisible,
    payoutDialogVisible,
    setPayoutDialogVisible,
    balanceSync,
    setBalanceSync,
    coinConfigDialogVisible,
    setCoinConfigDialogVisible,
    coinConfig,
    setCoinConfig,
    profit,
    setProfit,
    confirmationVisible,
    setConfirmationVisible
  };
};

export default BalanceStates;
