import React from "react";
import Spinner from "./Spinner";

import { StyledLoadButton } from "./styledSpinner";
import { StyledButton } from "../../styles/styledButton";

const LoadButton = ({color, text, mb, mt, className, spinnerColor, border, style}) => {
  return (
    <StyledLoadButton className={`loading-button ${className}`} style={style}>
      <StyledButton as="div" color={color} mb={mb} mt={mt} border={border} disabled>
        {text}
      </StyledButton>
      <div className="loading-button__spinner">
        <Spinner color={spinnerColor || `#000000`} size="22px"/>
      </div>
    </StyledLoadButton>
  );
};

export default LoadButton;