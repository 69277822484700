import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Notification from "rc-notification";
import { CurrentHonestyControl } from "./CurrentHonestyControl";
import { useBetween } from "use-between";
import { NavLink } from "react-router-dom";
import InputGroup from "../../inputGroup/InputGroup";

import { StyledButton } from "../../../styles/styledButton";
import {
  StyledHonestyControlDescriptions,
  StyledHonestyControlTitle,
  StyledHonestyControlWrapper
} from "./styledHonestyControl";
import { generatePath } from "../../../../utils/getLanguage";
import { fetchHonestyControl } from "../../../../utils/fetchHonestyControl";
import { useAnimationSyncData } from "../../../games/AnimationSyncDataStates";
import { StyledHeaderLeftSidebar } from "../Games/styledLeftSidebarGames";

const LeftSidebarHonestyControlContainer = React.memo(({ setVisible, setMenu, user }) => {
  const { t } = useTranslation("leftSidebar");

  const {
    currentHonestyControl,
    setCurrentHonestyControl,
    lastHonestyControl,
    setLastHonestyControl
  } = useBetween(CurrentHonestyControl);

  const [notification, setNotification] = useState({
    visible: false,
    type: "",
    message: ""
  });

  // const user = getUserInfo();

  const {
    clientSeed,
    serverSeed,
    serverSeedHash
  } = useAnimationSyncData(lastHonestyControl || {});

  return (
    <StyledHonestyControlWrapper>
      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      <StyledHeaderLeftSidebar>
        <h3>
          {t("judgement")}
        </h3>
        <span
          className="cancel" onClick={() => {
          setVisible(false);
          setMenu("");
        }}
        >✕</span>
      </StyledHeaderLeftSidebar>
      <StyledHonestyControlDescriptions>
        <p>
          {t("descHonestyControl")}{" "}
          <NavLink
            to={generatePath("/control")}
            onClick={() => {
              setVisible(false);
              setMenu("");
            }}
          >{t("judgement")}</NavLink>.
        </p>
      </StyledHonestyControlDescriptions>
      <StyledHonestyControlTitle>
        {t("titleNowHonestyControl")}
      </StyledHonestyControlTitle>
      <InputGroup
        id="clientSeed"
        label={t("clientSeed")}
        type="text"
        className="text"
        value={currentHonestyControl.clientSeed}
        textAlign="center"
        onChange={data => {
          setCurrentHonestyControl((prevState) => ({
              ...prevState,
              clientSeed: data.target.value
            }
          ));
        }}
      />
      <InputGroup
        id="serverSeedHash"
        label={t("serverSeedHash")}
        type="text"
        className="text"
        disabled={!user}
        value={user ? currentHonestyControl?.serverSeedHash : t("pleaseLogin")}
        readOnly
      />
      <StyledButton
        color="neutral"
        onClick={() => fetchHonestyControl(setCurrentHonestyControl, currentHonestyControl.game)}
      >
        {t("randomise")}
      </StyledButton>

      <StyledHonestyControlTitle>
        {t("titlePrevHonestyControl")}
      </StyledHonestyControlTitle>
      <InputGroup
        id="lastClientSeed"
        label={t("clientSeed")}
        type="text"
        className="text"
        value={clientSeed}
        readOnly
      />
      <InputGroup
        id="lastServerSeed"
        label={t("lastServerSeed")}
        type="text"
        className="text"
        value={serverSeed}
        readOnly
      />
      <InputGroup
        id="lastServerSeedHash"
        label={t("serverSeedHash")}
        type="text"
        className="text"
        value={serverSeedHash}
        readOnly
      />
    </StyledHonestyControlWrapper>
  );
});

export default LeftSidebarHonestyControlContainer;
