import styled from "styled-components";
import { device } from "../../styles/responsive/responsiveUtils";

const type = {
  type: ""
};

const changeType = (type, theme) => {
  switch (type) {
    case "error" :
      return `
        border-left: 4px solid #e21f3a;
      `;
    case "success" :
      return `
        border-left: 4px solid #2eac5b;
      `;
    case "info" :
      return `
        border-left: 4px solid #058bd8;
      `;
    case "dotted" :
      return `
        border: 1px dashed ${theme.dottedAlertBorder};
        background: transparent;
      `;
    default :
      return `
        border-left: 4px solid #ff9001;
      `;
  }
};

export const StyledAlertWrapper = styled("div", type)`
  ${({ center }) => center && "max-width: 1110px"};
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  padding: 12px 15px 12px 19px;
  color: ${({ theme }) => theme.mainTextColor};
  font-size: 15px;
  line-height: 1.35;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.alertMessageBg};

  @media screen and ${device('xs')} {
    font-size: 13px;
  }

  @media screen and ${device('xs', 'min')} and ${device('desktop')} {
    font-size: 14px;
  }

  a {
    color: #9f9f9f !important;
    border-bottom: 1px dashed #9f9f9f !important;
  }
  
  a.hover-white:hover {
    color: #fff !important;
    border-color: #fff !important;
  }

  ${({ type, theme }) => changeType(type, theme)}
`;