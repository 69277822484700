import AlertMessage from "../../elements/alert/Alert";
import {StyledButton} from "../../styles/styledButton";
import Dialog from "rc-dialog";
import {useTranslation} from "react-i18next";
import React, {useRef, useState} from "react";
import ReCaptcha from "../../elements/reCaptcha/ReCaptcha";
import {closableNotification} from "../../elements/notification/ClosableNotification";
import {responseStatus} from "../../../utils/consts";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import LoadButton from "../../elements/spinner/ButtonSpinner";

function ChangeProfitPrivacyModal({visible, setVisible, isHiddenProfit, setUserStatistics}) {
    const {t} = useTranslation('siteOptions');

    const [isLoading, setIsLoading] = useState(false);

    const recaptchaRef = useRef(null);

    const updateSettingProfit = () => {
        if (isLoading) return;
        setIsLoading(true);
        const captchaToken = recaptchaRef.current.getValue();
        recaptchaRef.current.reset();

        axios.put("/api/update-setting-profit", {captchaToken}, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setUserStatistics((prevState) => ({...prevState, hidden_profit: !prevState.hidden_profit}))
                setIsLoading(false);
                setVisible(false);
                closableNotification(isHiddenProfit ? t("publicPrivateSuccessfully") : t("privatePrivateSuccessfully"), "success");
            }
        }).catch(error => {
            setIsLoading(false);
            if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
                closableNotification(error.response.data.error, "error");
            }
        });
    };
    return (
        <Dialog
            visible={visible}
            wrapClassName="default-modal-wrapper"
            onClose={() => setVisible(false)}
            animation="zoom"
            maskAnimation="fade"
            title={t('profitSettings')}
            forceRender={false}
            footer={isLoading ? <LoadButton
                text={isHiddenProfit ? t("reveal") : t("hide")}
                color="neutral"
                type="submit"
                style={{width: '100%'}}
            /> : <StyledButton
                onClick={updateSettingProfit}
                color="neutral"
                type="submit"
                width="100"
            >
                {isHiddenProfit ? t("reveal") : t("hide")}
            </StyledButton>}
            className="default-modal"
        >
            <AlertMessage type={"error"}>
                {t('confirmChangePrivateProfit')}
            </AlertMessage>
            <ReCaptcha recaptchaRef={recaptchaRef} />
        </Dialog>
    )
}

export default ChangeProfitPrivacyModal